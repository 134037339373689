import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`Make at least 5 substantive posts for this lesson's threads as follows:`}</p>
    <ul>
      <li parentName="ul">{`Reply to at least 2 of the numbered questions posed in the threads below by 5pm Tuesday.`}</li>
      <li parentName="ul">{`Then keep an eye on these and the other threads and respond to other students' posts or the professor's new questions with at least 3 more replies by 5pm Friday.`}</li>
    </ul>
    <h3>{`1.1 Holtzblatt's Process`}</h3>
    <p>{`Generally, what is the process prescribed by Holtzblatt, et al?`}</p>
    <h3>{`1.2 System According to Holtzblatt`}</h3>
    <p>{`According to Holtzblatt, et. al. what is a system and how do you think "system thinking" affects design?`}</p>
    <h3>{`1.3 Design for Life`}</h3>
    <p>{`What are the components of “Design for life?” and what do you think of these components?`}</p>
    <h3>{`1.4 Immersion`}</h3>
    <p>{`What is immersion and how does it change the role/posture of the designer?`}</p>
    <h3>{`1.5 Needs and Unmet Desires`}</h3>
    <p>{`This unit's authors said, "Don’t look for users’ needs, look for unmet desires." Can you relate to this? Can you think of an example of this in your life?`}</p>
    <h3>{`1.6 Norman -- Affordances vs. Signifiers`}</h3>
    <p>{`What are affordances and signifiers? How are they different one from the other? Provide examples in order to clarify your points.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      